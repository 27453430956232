import moment from "moment";
import React from "react";

const ScheduleWeekSelect = ({ start_date, handleNextWeek, handlePrevWeek }) => {
  const weekStart = moment(start_date).week();
  const monthStart = moment(start_date).startOf("month").week();
  console.log({
    start_date,
    weekStart,
    monthStart,
  });
  const weekNumber = monthStart > weekStart ? weekStart : weekStart - monthStart + 1;
  return (
    <div className="row align-items-center">
      <div className="col-4 text-center hide-mobile">
        <button
          className="btn btn-outline-light btn-lg shadow-sm border"
          onClick={handlePrevWeek}
        >
          <i className="fa fa-chevron-left"></i>
        </button>
      </div>
      <div className="col-4 text-center hide-mobile">
        <h4 className="mb-0">Semana {weekNumber}</h4>
      </div>
      <div className="col-4 text-center hide-mobile">
        <button
          className="btn btn-outline-light btn-lg shadow-sm border"
          onClick={handleNextWeek}
        >
          <i className="fa fa-chevron-right"></i>
        </button>
      </div>
      <div className="col-6 px-1 show-mobile">
        <button
          className="btn btn-light ms-2 shadow-sm border"
          onClick={handlePrevWeek}
        >
          <i className="fa fa-chevron-left"></i> Anterior
        </button>
      </div>
      <div className="col-6 text-right show-mobile">
        <button
          className="btn btn-light shadow-sm border"
          onClick={handleNextWeek}
        >
          Sig. Semana <i className="fa fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ScheduleWeekSelect;
